<template>
  <div class="platformMonitor">
    <el-scrollbar class="elScroll">
      <div class="page-header">
        <div class="hr-top">
          <span>平台人数实时监控</span>
          <span>更新时间:{{nowtime}}</span>
          <span>
            <img src="@/assets/img/warn-1.png" alt="">
            <span>人数实时监控每1分钟将更新一次</span>
          </span>
        </div>
        <div class="hr-buttom">
          <div class="platform-cont">
            <div class="pla-item">
              <div class="pla-item-num" @click="watchEvent('onlineCount')">{{platformData.onlineCount || 0}}</div>
              <div class="pla-item-cont">
                <img src="@/assets/img/curriculumNum.png" alt="">
                <span>当前在线人数</span>
              </div>
            </div>
            <div class="pla-item">
              <div class="pla-item-num" @click="watchEvent('studyCount')">{{platformData.studyCount || 0}}</div>
              <div class="pla-item-cont">
                <img src="@/assets/img/coursewareLearn.png" alt="">
                <span>当前课件学习人数</span>
              </div>
            </div>
            <div class="pla-item">
              <div class="pla-item-num" @click="watchEvent('examCount')">{{platformData.examCount || 0}}</div>
              <div class="pla-item-cont">
                <img src="@/assets/img/exam.png" alt="">
                <span>当前考试人数</span>
              </div>
            </div>
            <div class="pla-item">
              <div class="pla-item-num" @click="watchEvent('liveCount')">{{platformData.liveCount || 0}}</div>
              <div class="pla-item-cont">
                <img src="@/assets/img/live.png" alt="">
                <span>当前直播观看人数</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <trend></trend>
      <daily-cumulative-use></daily-cumulative-use>
      <wavePeak></wavePeak>
    </el-scrollbar>

    <!-- 实时人数弹窗 -->
    <dialogDiyNew :isShow="isShowPlaNum" :dialogDiyData="dialogPlaData"
    @dialogEvent="dialogPlaEvent">
      <div slot="dialogContent" class="resource_area">
        <platformTable :nowtime="nowtime" ref="pt"></platformTable>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
const trend = () => import('@/components/platformMonitor/trend')
const wavePeak = () => import('@/components/platformMonitor/wavePeak')
const dailyCumulativeUse = () => import('@/components/platformMonitor/dailyCumulativeUse')
const dialogDiyNew = () => import('@/components/commonModule/dialogDiyNew')
const platformTable = () => import('@/components/platformMonitor/Module/platformTable')
export default {
  name: 'platformMonitor',
  components: {
    trend,
    wavePeak,
    dailyCumulativeUse,
    dialogDiyNew,
    platformTable
  },
  data() {
    return {
      platformData: {},
      isShowPlaNum: false,
      dialogPlaData: {
        title: "",
        sizeStyle: { width: "1200px", height: "820px" }
      },
      nowtime: null,
      timer: null,
    }
  },
  mounted() {
    this.getTimes()
    this.getPlatformNumEvent()
    this.timerEvent()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    // 人数实时监控每1分钟将更新一次
    timerEvent() {
      this.timer = setInterval(() => {
        this.getTimes()
        this.getPlatformNumEvent()
      }, 60000)
    },
    getPlatformNumEvent() {
      this.$request.getPlatformNum().then(res => {
        if(res.data.code == 0) {
          this.platformData = res.data.data
        }
      })
    },
    // 获取当前时间
    getTimes() {
      // if(this.nowtime) return this.nowtime
      this.nowtime = new Date().Format("yyyy-MM-dd HH:mm:ss")
      return this.nowtime
    },
    dialogPlaEvent() {
      this.isShowPlaNum = !this.isShowPlaNum
    },
    watchEvent(key) {
      let title;
      let type;
      switch(key) {
        case 'onlineCount':
          title = '当前在线人数'
          type = 0
          break
        case 'studyCount':
          title = '当前课件学习人数'
          type = 1
          break
        case 'examCount':
          title = '当前考试人数'
          type = 2
          break
        case 'liveCount':
          title = '当前直播观看人数'
          type = 3
          break
      }
      this.dialogPlaData.title = title
      this.dialogPlaEvent()
      this.$nextTick(() => {
        this.$refs.pt.showEvent({type})
      })
    }
  },
}
</script>

<style lang='less' scoped>
  .platformMonitor {
    width: 100%;
    height: 100%;
    /deep/ .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    /deep/ .el-scrollbar__view {
      padding: 0!important;
    }
    .page-header {
      background-color: #fff;
      .hr-top {
        padding: 30px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EFEFEF;
        & > span:nth-of-type(1) {
          font-size: 26px;
          color: #16222E;
        }
        & > span:nth-of-type(2) {
          font-size: 14px;
          color: #666666;
          margin: 0 20px;
        }
        & > span:nth-of-type(3) {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #666666;
          img {
            width: 16px;
          }
        }
      }
      .platform-cont {
        display: flex;
        justify-content: space-between;
        .pla-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 33px 0;
          border-right: 1px solid #EFEFEF;
          &:nth-last-of-type(1) {
            border: 0 none;
          }
          .pla-item-num {
            font-size: 40px;
            font-weight: 400;
            color: #363840;
            cursor: pointer;
          }
          .pla-item-cont {
            display: flex;
            align-items: center;
          }
          img {
            width: 18px;
            height: 17px;
            margin-right: 6px;
          }
          span {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
</style>